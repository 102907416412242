<template>
  <div class="challenges-ranking-block section-padding">
    <div class="challenges-ranking-tabs-header">
      <div class="container d-flex">
        <button type="button" class="challenges-ranking-tabs-btn active" rel="official" @click="toggleRankingTabs">Ranking Oficial</button>
        <button type="button" class="challenges-ranking-tabs-btn" rel="non-official" @click="toggleRankingTabs">Ranking Não Oficial</button>
      </div>
    </div>
    <div class="challenges-ranking-tabs-body" v-if="rankings.model">
      <div class="container d-lg-flex">
        <div class="challenges-ranking-tab-content active" rel="official">
          <div class="challenges-ranking-list">
            <div class="challenges-ranking-item" v-for="(item,index) in rankings.model.official" :key="index">
              <div class="d-flex justify-content-between align-items-center" v-if="index <= 4">
                <div class="d-flex justify-content-start align-items-center">
                  <p class="challenges-ranking-item-position">{{ item.position }}º</p>
                  <div class="challenges-ranking-item-image">
                    <img src="https://via.placeholder.com/30" alt="">
                  </div>
                  <p class="challenges-ranking-item-title">{{ item.customer }}</p>
                </div>
                <p class="challenges-ranking-item-score">{{ item.time }}</p>
              </div>
              <div class="ranking-separator d-flex justify-content-start align-items-center" v-if="index > 4"></div>
              <div class="d-flex justify-content-between align-items-center" v-if="index > 4">
                <div class="d-flex justify-content-start align-items-center">
                  <p class="challenges-ranking-item-position">{{ item.position }}º</p>
                  <div class="challenges-ranking-item-image">
                    <img src="https://via.placeholder.com/30" alt="">
                  </div>
                  <p class="challenges-ranking-item-title">{{ item.customer }}</p>
                </div>
                <p class="challenges-ranking-item-score">{{ item.time }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="challenges-ranking-tab-content" rel="non-official">
          <div class="challenges-ranking-list">
            <div class="challenges-ranking-item" v-for="(item,index) in rankings.model.unOfficial" :key="index">
              <div class="d-flex justify-content-between align-items-center" v-if="index <= 4">
                <div class="d-flex justify-content-start align-items-center">
                  <p class="challenges-ranking-item-position">{{ item.position }}º</p>
                  <div class="challenges-ranking-item-image">
                    <img src="https://via.placeholder.com/30" alt="">
                  </div>
                  <p class="challenges-ranking-item-title">{{ item.customer }}</p>
                </div>
                <p class="challenges-ranking-item-score">{{ item.time }}</p>
              </div>
              <div class="ranking-separator d-flex justify-content-start align-items-center" v-if="index > 4"></div>
              <div class="d-flex justify-content-between align-items-center" v-if="index > 4">
                <div class="d-flex justify-content-start align-items-center">
                  <p class="challenges-ranking-item-position">{{ item.position }}º</p>
                  <div class="challenges-ranking-item-image">
                    <img src="https://via.placeholder.com/30" alt="">
                  </div>
                  <p class="challenges-ranking-item-title">{{ item.customer }}</p>
                </div>
                <p class="challenges-ranking-item-score">{{ item.time }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "rankings-component",
  props: {
    rankings: {
      required: true,
    },
  },
  methods: {
    toggleRankingTabs(e) {
      let target = e.currentTarget;
      let tabID = target.getAttribute('rel');
      let selectedContent = document.querySelectorAll('.challenges-ranking-tab-content[rel="'+ tabID +'"]')[0];

      let buttons = Array.from(document.querySelectorAll('.challenges-ranking-tabs-btn'));
      let contents = Array.from(document.querySelectorAll('.challenges-ranking-tab-content'));

      if ( ! target.classList.contains('active') ) {
        buttons.forEach(node => {
          node.classList.remove('active');
        });

        contents.forEach(node => {
          node.classList.remove('active');
        });

        target.classList.add('active');
        selectedContent.classList.add('active');
      }
    }
  }

}
</script>

<style scoped>

</style>