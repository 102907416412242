<template>
  <div class="box-modal-wrapper box-subscribe-modal">
    <div class="container">
      <div class="box-modal-header d-flex justify-content-center align-items-center">
          <button type="button" class="close-modal-btn" @click="closeSubscribeModal">
            <closeIcon/>
          </button>
          <div class="box-modal-header-logo">
            <headerLogo/>
          </div>
      </div>
      <div class="box-modal-body d-flex justify-content-start align-items-center flex-column">
        <p class="box-modal-title">Subscreva o conteúdo premium</p>
        <div class="plan-item-wrapper">
          <div class="plan-item d-flex flex-column justify-content-center align-items-center">
            <div class="plan-item-icon">
              <lock/>
            </div>
            <p class="plan-item-title">PREMIUM</p>
            <p class="plan-item-subtitle">Acesso a todos os treinos e execícios disponiveis na plataforma</p>
            <div class="plan-item-price">
              <span class="smaller">desde</span>
              5,99€
              <span>/ Mês</span>
            </div>
            <router-link to="/reserved-area-plans" class="generic-btn red-gradient plan-item-btn">ver planos</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import lock from "@/components/svg/lock2.vue";
import headerLogo from "@/components/svg/logo.vue";
import closeIcon from "@/components/svg/close.vue";

export default {
  name: "subscribe-modal",
  components: {
    closeIcon,
    headerLogo,
    lock
  },
  methods: {
    closeSubscribeModal() {
      let element = document.getElementsByClassName("box-subscribe-modal")[0];

      if ( element.classList.contains("open") ) {
        element.classList.remove("open");
      }
    }
  }
}


</script>