<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="25" viewBox="0 0 18 25">
    <path fill="#FFF" d="M30.394 110.508v.969c0 .451.356.816.796.816.44 0 .796-.365.796-.816v-.974c.488-.288.822-.821.822-1.436 0-.914-.726-1.66-1.618-1.66-.892 0-1.618.746-1.618 1.66 0 .604.319 1.148.822 1.441m8.195 3.541c0 .196-.044 1.942-1.591 2.008-2.309.092-11.487.01-11.601.005-.142 0-1.385-.07-1.623-2.35v-9.454H38.59v9.791zm-7.43-19.685c4.472 0 4.728 3.802 4.743 4.21v4.052h-9.486l-.001-3.829c.005-.185.159-4.433 4.743-4.433zm8.227 8.262h-1.892v-4.074c-.05-2.012-1.44-5.82-6.336-5.82-4.897 0-6.285 3.938-6.335 6.043v3.851h-1.845c-.44 0-.796.365-.796.816v10.314c0 .027.001.054.004.081.307 3.139 2.269 3.917 3.217 3.857.242.005 3.944.038 7.21.038 1.869 0 3.595-.01 4.45-.043 2.466-.104 3.119-2.427 3.119-3.64v-10.607c0-.451-.357-.816-.796-.816z" transform="translate(-42 -367) translate(19.818 274.268)"/>
  </svg>
</template>

<script>
export default {
  name: 'lock'
}
</script>
