<template>
  <div class="dashboard workout-detail">
    <div class="workout-detail-block">
      <div class="workout-detail-top-wrapper">
        <div class="container">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex justify-content-start align-items-center">
              <button type="button" class="heading-back" @click="$router.go(-1)">
                <chevronLeft/>
              </button>
              <p class="workout-detail-top-title d-none d-lg-block">{{ challengeItem.title }}</p>
            </div>
            <div class="d-flex justify-content-end align-items-center">
              <!--<button type="button" class="workout-detail-top-btn">
                <shareIcon/>
              </button>-->
            </div>
          </div>
        </div>
      </div>
      <div class="workout-detail-content-wrapper">
        <div class="container">
          <div class="workout-detail-content">
            <div class="row">
              <div class="col-12 col-lg-7" v-if="challengeItem.is_premium > 0">
                <div v-if="!!challengeItem.media">
                  <div class="workout-detail-image" v-if="!!challengeItem.media.general" v-on="!!challengeItem.media.videos && this.$root.userInfo.premium_until != null ? { click: toggleVideoModal } : { click: toggleSubscribeModal }" :class="( challengeItem.media.videos ) ? 'has-video' : '' ">
                    <img :src="challengeItem.media.general[0].image" alt="">
                    <div class="workout-detail-image-content">
                      <div class="container">
                        <div class="d-flex justify-content-start align-items-end">
                          <div class="workout-detail-video-btn">
                            <playIcon v-if="this.$root.userInfo.premium_until != null" />
                            <lock class="lock" v-else />
                          </div>
                          <div class="d-lg-none">
                            <p class="workout-detail-subtitle">Desafio</p>
                            <p class="workout-detail-title">{{ challengeItem.title }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-7" v-if="challengeItem.is_premium == 0">
                <div v-if="!!challengeItem.media">
                  <div class="workout-detail-image"  v-if="!!challengeItem.media.general" v-on="!!challengeItem.media.videos ? { click: toggleVideoModal } : {}" :class = "( challengeItem.media.videos ) ? 'has-video' : '' ">
                    <img :src="challengeItem.media.general[0].image" alt="">
                    <div class="workout-detail-image-content">
                      <div class="container">
                        <div class="d-flex justify-content-start align-items-end">
                          <div class="workout-detail-video-btn">
                            <playIcon/>
                          </div>
                          <div class="d-lg-none">
                            <p class="workout-detail-subtitle">Desafio</p>
                            <p class="workout-detail-title">{{ challengeItem.title }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-5">
                <div class="workout-detail-status" v-if="!!challengeItem.isActive && challengeItem.isActive">
                  <p>A Decorrer…</p>
                </div>
                <div class="workout-detail-extra-info" v-if="!!challengePosition || !!challengeTime">
                  <div class="d-flex">
                    <div class="workout-detail-extra-info-column workout-detail-extra-info-ranking" v-if="!!challengePosition">
                      <p class="workout-detail-extra-info-ranking-title">{{ challengePosition }}</p>
                      <p class="workout-detail-extra-info-ranking-subtitle">Posição Oficial</p>
                    </div>
                    <div class="workout-detail-extra-info-column workout-detail-extra-info-time" v-if="!!challengeTime">
                      <p class="workout-detail-extra-info-time-title">O teu tempo</p>
                      <p class="workout-detail-extra-info-time-subtitle">{{ challengeTime }}</p>
                    </div>
                  </div>
                </div>
                <div class="workout-detail-text-wrapper">
                  <div class="container">
                    <p class="workout-detail-text-title">Descrição do Treino</p>
                    <div class="workout-detail-text-description-wrapper">
                      <div class="workout-detail-text-description" v-html="challengeItem.description"></div>
                      <div class="workout-detail-text-description-btn-wrapper d-flex justify-content-center align-items-center">
                        <button type="button" class="workout-detail-text-description-btn d-flex justify-content-center align-items-center" @click="toggleTextHeight">
                          <p>Ler Mais</p>
                          <span class="workout-detail-text-description-btn-icon">
                          <chevronDown/>
                        </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="workout-detail-btn-wrapper d-none d-lg-block">
                  <div  v-if="!!challengeItem.isActive && challengeItem.isActive" class="d-flex justify-content-center align-items-center">
                    <button type="button" class="generic-btn red-gradient workout-detail-btn" @click="toggleChallengesModal">Participar Desafio</button>
                  </div>
                  <div v-else class="d-flex justify-content-center align-items-center">
                    <p class="generic-btn grey-gradient workout-detail-btn">Desafio Terminado</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <equipmentBlock :parentItem="challengeItem" :equipment="challengeItem.equipments" v-if="challengeItem.equipments"/>
    <rankingsBlock :rankings="rankings" />
    <div class="workout-detail-btn-wrapper d-lg-none">
      <div class="container d-flex justify-content-center align-items-center">
        <button type="button" class="generic-btn red-gradient workout-detail-btn" @click="toggleChallengesModal">Participar Desafio</button>
      </div>
    </div>
    <div v-if="!!challengeItem.media">
      <videoModal :video="challengeItem.media.videos[0]" :key="challengeItem.id" v-if="!!challengeItem.media.videos" :productID="challengeItem.id"/>
    </div>
    <challengesModal :productID="challengeItem.id"/>
    <subscribeModal/>
  </div>
</template>

<script>
import equipmentBlock from "@/components/equipment/equipment-block.vue";
import rankingsBlock from "@/components/challenges/rankings-component.vue";
import chevronLeft from "@/components/svg/chevron-left.vue";
import shareIcon from "@/components/svg/share-icon.vue";
import clock from "@/components/svg/clock.vue";
import level from "@/components/svg/level.vue";
import fire from "@/components/svg/fire.vue";
import chevronDown from "@/components/svg/chevron-down.vue";
import playIcon from "@/components/svg/play.vue";
import videoModal from "@/components/modals/video-modal.vue";
import challengesModal from "@/components/modals/challenges-modal.vue";
import subscribeModal from "@/components/modals/subscribe-modal.vue";
import lock from "@/components/svg/lock.vue";

import {EventBus} from "@/event-bus";
import axios from "axios";

export default {
  name: "challenges-detail",
  data () {
    return {
      lang: this.$root.siteLang,
      challengeItem: [],
      rankings: [],
      challengePosition: '',
      challengeTime: '',
    }
  },
  metaInfo () {
    return {
      title: this.$root.translations.desafios,
    }
  },
  components : {
    equipmentBlock,
    rankingsBlock,
    chevronLeft,
    shareIcon,
    clock,
    level,
    fire,
    chevronDown,
    playIcon,
    videoModal,
    challengesModal,
    subscribeModal,
    lock
  },
  methods: {
    toggleTextHeight() {
      let element = document.getElementsByClassName("workout-detail-text-description-wrapper")[0];

      if ( !element.classList.contains("open") ) {
        element.classList.add("open");
      }
    },
    toggleVideoModal() {
      let element = document.getElementsByClassName("box-video-modal")[0];

      if ( element.classList.contains("open") ) {
        element.classList.remove("open");
      } else {
        element.classList.add("open");
        EventBus.$emit("bus-playVideo");
      }
    },
    toggleChallengesModal() {
      let element = document.getElementsByClassName("box-challenges-modal")[0];

      if ( element.classList.contains("open") ) {
        element.classList.remove("open");
      } else {
        element.classList.add("open");
      }
    },
    toggleSubscribeModal() {
      let element = document.getElementsByClassName("box-subscribe-modal")[0];

      if ( element.classList.contains("open") ) {
        element.classList.remove("open");
      } else {
        element.classList.add("open");
      }
    }
  },
  mounted() {
    EventBus.$on('bus-toggleVideoModal', () => {
      let element = document.getElementsByClassName("box-video-modal")[0];

      if ( element.classList.contains("open") ) {
        element.classList.remove("open");
      } else {
        element.classList.add("open");
        EventBus.$emit("bus-playVideo");
      }
    });
  },
  created() {
    const areaSlugId = this.$route.params.id;
    const areaSlugParts = areaSlugId.split('-');
    const idAreaItem = areaSlugParts[areaSlugParts.length - 1];

    let authKey = window.$cookies.get("authKey");

    axios
        .get(process.env.VUE_APP_API_URL + 'products/'+ idAreaItem + '?with_category_title=0&lang=' + this.lang)
        .then((response) => {
          this.challengeItem = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        }).finally((e) => {});

    axios
        .get(process.env.VUE_APP_API_URL + 'products/challenge', { params: { product: idAreaItem },
          headers: {
            Authorization: 'Bearer ' + authKey
          }
        })
        .then((response) => {
          this.rankings = response.data;

          let customerID = this.$root.userInfo.id_customer;

          this.rankings.model.unOfficial.forEach(node => {
            if ( node.customerId == customerID ) {
              this.challengeTime = node.time;
            }
          });

          this.rankings.model.official.forEach(node => {
            if ( node.customerId == customerID ) {
              this.challengePosition = node.position;
              this.challengeTime = node.time;
            }
          });
        })
        .catch((e) => {
          this.errors.push(e);
        }).finally((e) => {});
  },
}
</script>