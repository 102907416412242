<template>
  <div class="box-modal-wrapper box-challenges-modal light-grey-bg">
    <div class="container">
      <div class="box-modal-header d-flex justify-content-center align-items-center">
          <button type="button" class="close-modal-btn" @click="closeChallengesModal">
            <closeIcon/>
          </button>
          <div class="box-modal-header-logo">
            <headerLogo/>
          </div>
      </div>
      <FormulateForm class="box-modal-body d-flex justify-content-between align-items-center flex-column" v-model="values" @submit="submitChallenge">
        <div class="box-modal-flex d-flex justify-content-center align-items-center flex-column">
          <p class="feedback-modal-title">PARTICIPAR DESAFIO</p>
          <p class="feedback-modal-subtitle">Treino de 200 Repetições</p>
          <div class="feedback-form-wrapper">
            <div class="challenges-modal-score-block">
              <div class="challenges-modal-form-field challenges-modal-score-input">
                <label>
                  <p>Qual foi o teu Tempo?</p>
                  <FormulateInput type="text" name="tempo" v-model="time" v-facade="'##:##:##'" validation="required" />
                </label>
              </div>
              <div class="challenges-modal-form-field challenges-modal-score-input">
                <label>
                  <p>Queres deixar um comentário?</p>
                  <FormulateInput type="textarea" name="comentario" placeholder="Deixar Mensagem" rows="4" v-model="comment" />
                </label>
              </div>
            </div>
            <div class="challenges-modal-score-block">
              <div class="form-field">
                <label>
                  <p>Deixe aqui o seu video</p>
                  <FormulateInput type="text" name="tempo" v-model="attachment" placeholder="Coloque aqui o link do youtube" />
                </label>
                <p class="form-field-note">A participação do desafio só será oficial após visualização do vídeo.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="box-modal-body-button-wrapper">
          <button type="submit" class="generic-btn red-gradient big-text">Enviar</button>
          <p v-if="success" class="box-modal-body-button-note text-success">Obrigado por participar neste desafio!</p>
        </div>

      </FormulateForm>
    </div>
  </div>
</template>

<script>

import headerLogo from "@/components/svg/logo-black.vue";
import closeIcon from "@/components/svg/close.vue";
import axios from "axios";
import qs from "qs";
import {facade} from 'vue-input-facade'

export default {
  name: "feedback-modal",
  data () {
    return {
      lang: this.$root.siteLang,
      values: {},
      time: '00:00:00',
      comment: '',
      attachment: '',
      success: false,
    }
  },
  props: {
    productID: {
      required: true,
    },
  },
  components: {
    headerLogo,
    closeIcon,
  },
  directives: { facade },
  methods: {
    closeChallengesModal: function () {
      document.getElementsByClassName("box-challenges-modal")[0].classList.remove('open');
    },
    submitChallenge: function() {
      let authKey = window.$cookies.get("authKey");

      const requestBody = {
        product: this.$props.productID,
        time: this.time,
        comment: this.comment,
        attachment: this.attachment,
      };

      axios
          .post(process.env.VUE_APP_API_URL + 'products/challenge', qs.stringify(requestBody),{
            headers: {
              Authorization: 'Bearer ' + authKey
            }
          })
          .then((response) => {
            this.success = true;
            this.showMessage = true;
            this.formResponseMessage = 'Submetido com sucesso!';
            setTimeout(() => { this.closeChallengesModal(); }, 2000);

          })
          .catch((e) => {
            this.errors.push(e);
            this.showMessage = true;
            this.formResponseMessage = 'An error occurred.';
          });
    }
  },
}


</script>